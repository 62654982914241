import ReactDOM from 'react-dom/client';
import React, { Suspense, useEffect, useState } from 'react';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import LoadingScreen from './components/loading-screen';
import { getPartnerDetailsFromDomain } from './services/register/getPartnerDetailsFromDomain';

// ----------------------------------------------------------------------

const fetchWhitelabelConfigData = async (setLoading) => {
  try {
    const url = new URL(window.location.href);
    const hostname = url.hostname.replace(/^www\./, '');
    const response = await getPartnerDetailsFromDomain(hostname);

    const partnerDetails = response || {};
    const { assets, metadata, _id } = partnerDetails;

    localStorage.setItem('partnerId', _id);
    localStorage.setItem('landingLogo', assets.logo);
    localStorage.setItem('faviconLogo', assets.icon);
    localStorage.setItem('displayName', metadata.display_name);
    // localStorage.setItem("metaTitle", meta_title);
    localStorage.setItem('metaDiscription', metadata.description);
    localStorage.setItem('metaKeywords', metadata.keywords);
    localStorage.setItem('primaryColor', metadata.primary_color);
    localStorage.setItem('secondaryColor', metadata.secondary_color);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const RootComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getConfig = async () => {
      await fetchWhitelabelConfigData(setLoading);
    };
    getConfig();
  }, []);

  if (loading) {
    return <Suspense fallback={<LoadingScreen />} />;
  }

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<RootComponent />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
