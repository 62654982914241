// utils
import axios from '../../utils/axios';
import { USER_GLOBAL_HOST_API_KEY } from '../../config-global';

const createUser = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${USER_GLOBAL_HOST_API_KEY}/auth/register`,
      data,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { createUser };
