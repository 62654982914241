import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import { getAllPartnersOfUser } from '../services/register/getAllPartnersOfUser';
import axios from '../utils/axios';
//
import { setSession } from './utils';
import { getUserData } from '../services/register/getUserData';

// ----------------------------------------------------------------------
const USER_GLOBAL_HOST_API_KEY = process.env.REACT_APP_USER_GLOBAL_HOST_API_KEY || '';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const _id = localStorage.getItem('_id');
      if (_id) {
        const userData = await getUserData(_id);
        setSession(JSON.stringify(userData), _id);

        let partnerId = '';
        if (localStorage.getItem('partnerIdmanage')) {
          partnerId = localStorage.getItem('partnerIdmanage');
        } else {
          partnerId = localStorage.getItem('partnerId');
        }

        const allPartnersOfUser = await getAllPartnersOfUser();

        const partner = allPartnersOfUser.find(
          (item) => (item.partner_type === 'affiliate' ? item.parent : item._id) === partnerId
        );

        if (partner) {
          localStorage.setItem('currentPartner', JSON.stringify(partner));
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user: userData,
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    try {
      const data = new URLSearchParams();
      data.append('email', email);
      data.append('password', password);
      const response = await axios({
        method: 'post',
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/login`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data,
      });
      const userData = await response.data;
      const { _id } = userData;
      setSession(JSON.stringify(userData), _id);

      let partnerId = '';
      if (localStorage.getItem('partnerIdmanage')) {
        partnerId = localStorage.getItem('partnerIdmanage');
      } else {
        partnerId = localStorage.getItem('partnerId');
      }
      const allPartnersOfUser = await getAllPartnersOfUser();

      const partner = allPartnersOfUser.find(
        (item) => (item.partner_type === 'affiliate' ? item.parent : item._id) === partnerId
      );

      if (partner) {
        localStorage.setItem('currentPartner', JSON.stringify(partner));
        dispatch({
          type: 'LOGIN',
          payload: {
            user: userData,
          },
        });
      } else {
        throw new Error('You are not authorized to access this partner');
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }, []);

  // MANAGE ACCOUNT
  const manageAccount = async (access_token, refresh_token) => {
    try {
      const userProfile = await axios.get('/lms_user/api/user-profile', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const res = userProfile.data;
      const profile = JSON.stringify(res);
      setSession(access_token, profile, refresh_token);

      dispatch({
        type: 'LOGIN',
      });
    } catch (error) {
      console.log(error);
    }
  };

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/auth/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await axios({
      method: 'get',
      url: `${USER_GLOBAL_HOST_API_KEY}/auth/logout`,
    });
    setSession(null, null);
    localStorage.removeItem('currentPartner');
    localStorage.removeItem('partnerIdmanage');
    localStorage.removeItem('currentUser');
    dispatch({ type: 'LOGOUT' });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      manageAccount,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
