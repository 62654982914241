import GoogleIcon from '@mui/icons-material/Google';
import { useEffect, useState } from 'react';

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { getNavBarOnboardConfig } from '../../../services/whatsapp/getNavBarOnboardConfig';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  users: icon('users'),
  whatsApp: icon('whatsApp'),
  partners: icon('partners'),
  accountSetup: icon('accountSetup'),
  reports: icon('reports'),
  whitelable: icon('whitelable-setup'),
  profile: icon('profile'),
  notifications: <NotificationsNoneOutlinedIcon fontSize="large" />,
};

const whatsappDemoUrl = new URL(window.location.href);

const useNavConfig = () => {
  const [navConfig, setNavConfig] = useState([]);
  const [elligible, setElligible] = useState(null);

  const [partner_type, setPartner_type] = useState('');

  useEffect(() => {
    const p_data = JSON.parse(localStorage.getItem('currentPartner')).partner_type;
    if (p_data) {
      setPartner_type(p_data);
    }
  }, []);

  useEffect(() => {
    const elligiblityStatus = async () => {
      try {
        const res = await getNavBarOnboardConfig(); // Wait for the promise to resolve
        setElligible(res); // Safely handle cases where `res` or `res.plans` might be undefined
      } catch (error) {
        console.error('Error fetching status:', error);
      }
    };
    elligiblityStatus();
  }, []);

  useEffect(() => {
    // const partner_type = JSON.parse(localStorage.getItem('currentPartner'))?.partner_type;
    const navArr = [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        // subheader: 'general v4.1.0',
        items: [
          { title: 'Customers', path: PATH_DASHBOARD.customers.root, icon: ICONS.users },
          (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
            title: 'Partners',
            path: PATH_DASHBOARD.partners.root,
            icon: ICONS.partners,
          },
          (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
            title: 'Google Workspace',
            path: PATH_DASHBOARD.googleWorkspace.root,
            icon: <GoogleIcon />,
            children: [
              {
                title: 'Customers',
                path: PATH_DASHBOARD.googleWorkspace.customers,
              },
              {
                title: 'Transactions',
                path: PATH_DASHBOARD.googleWorkspace.transactions,
              },
            ],
          },
          {
            title: 'WhatsApp',
            path: PATH_DASHBOARD.whatsApp.root,
            icon: ICONS.whatsApp,
            children: [
              (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
                title: 'Partners',
                path: PATH_DASHBOARD.whatsApp.partners,
              },
              {
                title: 'Customers',
                path: PATH_DASHBOARD.whatsApp.customers,
              },
              (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
                title: 'Transactions',
                path: PATH_DASHBOARD.whatsApp.transactions,
              },
              (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
                title: 'Templates',
                path: PATH_DASHBOARD.whatsApp.templates,
              },
              {
                title: 'Start Demo',
                path: `${whatsappDemoUrl.origin}/whatsapp/demo`,
              },
              (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
                title: 'Customize Demo',
                path: PATH_DASHBOARD.whatsApp.customizeDemo,
              },
              {
                title: 'Fix Display Name',
                path: PATH_DASHBOARD.whatsApp.fixDisplayName,
              },
              elligible?.status === true && {
                title: 'Onboarding Config',
                path: PATH_DASHBOARD.whatsApp.onboardingConfig,
              },
              {
                title: 'Billing',
                path: PATH_DASHBOARD.whatsApp.billing,
                children: [
                  {
                    title: 'Billing Profile',
                    path: PATH_DASHBOARD.whatsApp.billingProfile,
                  },
                  {
                    title: 'Billing Payment',
                    path: PATH_DASHBOARD.whatsApp.billingpayment,
                  },
                  {
                    title: 'Customer Invoice',
                    path: PATH_DASHBOARD.whatsApp.customerInvoice,
                  },
                ],
              },
            ],
          },
          (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
            title: 'Reports',
            path: PATH_DASHBOARD.reports,
            icon: ICONS.reports,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
            title: 'users',
            path: PATH_DASHBOARD.users.root,
            icon: ICONS.users,
            // children: [
            //   { title: 'Four', path: PATH_DASHBOARD.user.four },
            //   { title: 'Five', path: PATH_DASHBOARD.user.five },
            //   { title: 'Six', path: PATH_DASHBOARD.user.six },
            // ],
          },
          // {
          //   title: 'Partner Profile',
          //   path: PATH_DASHBOARD.partnersProfile.root,
          //   icon: ICONS.profile,
          //   children: [
          //     {
          //       title: 'Profile',
          //       path: PATH_DASHBOARD.partnersProfile.partnerProfile,
          //     },
          //     {
          //       title: 'Billing Profile',
          //       path: PATH_DASHBOARD.partnersProfile.billingProfile,
          //     },
          //   ],
          // },
          {
            title: 'Partner Profile',
            path: PATH_DASHBOARD.partners.profile,
            icon: ICONS.profile,
          },
          {
            title: 'Notifications',
            path: PATH_DASHBOARD.notifications,
            icon: ICONS.notifications,
          },
          (partner_type === 'whitelabel' || partner_type === 'whitelable') && {
            title: 'whitelabel',
            path: PATH_DASHBOARD.whitelableConfig,
            icon: ICONS.whitelable,
          },
          // {
          //   title: 'Lead Setup',
          //   path: PATH_DASHBOARD.leadsetup,
          //   icon: <LeaderboardOutlinedIcon />,
          // },
        ],
      },
    ];

    setNavConfig(navArr);
  }, [elligible?.status, partner_type]);

  return navConfig;
};

export default useNavConfig;
