// utils
// import axios from 'axios';
import { PARTNER_ONBOARDING_URL } from '../../config-global';
import axios from '../../utils/axios';

const getNavBarOnboardConfig = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${PARTNER_ONBOARDING_URL}/api/is_eligible`,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { getNavBarOnboardConfig };
